<template>
    <div>
        <div class="search">
            <index-search></index-search>
        </div>
        <rank></rank>
        <div class="ad">
            <div class="title">BIGEMAP”一心三端”助力全行业打造数字孪生世界</div>
            <div><button class="btn">立即体验</button></div>
        </div>
        <div class="index-answer-list base-width">
            <el-row :gutter="15">
                <el-col :span="17" :offset="1">
                    <question-list mode="index"></question-list>
                </el-col>
                <el-col :span="5">
                    <today-hot></today-hot>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import IndexSearch from "@/components/index/top/index-search.vue";
import Rank from '@/components/index/rank/index.vue';
import QuestionList from "@/components/wenda/left/question-list.vue";
import TodayHot from "@/components/wenda/right/today-hot.vue";
export default {
    name: "index",
    components: {
        IndexSearch,Rank,QuestionList,TodayHot
    },
    mounted() {

    },
    created() {

    },
    data: function () {
        return {

        }
    },
    methods: {}
}

</script>
<style scoped lang="less">
    .search {
        height: 340px;
        align-items: center;
        justify-content: center;
        display: flex;
        background: url("/static/bbs/images/index/search_bg.png") top center no-repeat;
        background-size: cover;
    }
    .ad{
        margin-top: 20px;
        background: url("/static/bbs/images/index/info.png") top center no-repeat;
        background-size: cover;
        //height: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        padding: 30px 0;
        .title{
            font-family: YouSheBiaoTiHei;
            font-size: 26px;
            padding-bottom: 10px;
        }

        .btn{
            font-family: YouSheBiaoTiHei;
            color: #fff;
            border: none;
            font-size: 24px;
            border-radius: 50px;
            background-color: rgba(48, 125, 219, 1);;
            padding: 4px 40px;
        }
    }

    .index-answer-list{
        padding: 0 8px;
        margin-top: 20px;
    }
</style>
