<template>
    <div class="q-detail">
        <!--        <div class="answered">-->
        <!--            <span>-->
        <!--                {{ content.sum && content.sum.reply || 0 }}-->
        <!--            </span>-->
        <!--        </div>-->
        <div class="q-part">
            <div v-if="needUsername" class="q-user">
                <div class="face">
                    <img :src="content.username.face||'/static/bbs/images/answer/face.png'" alt="">
                </div>
                <div class="user-info">
                    <span class="username">{{ content.username.nickname || content.username.username||'未知' }}
<!--                        <span class="official">官方</span>-->
                    </span>
                    <span class="time">
                            <format-time readable="1" :time="content.create_time" format="Y-m-d"></format-time>
                        </span>
                </div>
            </div>
            <a target="_blank" :href="'/wenda/detail/'+content.answer_id+'.html'" class="q-title">
                <span class="q-icon">
                    <i :class="content.status===100?'el-icon-success':'el-icon-question'"></i>
                </span>
                <p class="a-title bbs-one-line">
                    <span v-html="content.highlight&&content.highlight.title&&content.highlight.title[0]||content.title"></span>
                </p>
                <div class="q-info">
                    <div v-if="content.thumb" class="thumb">
                        <img :src="content.thumb" alt="">
                    </div>
                    <p class="bbs-third-color content-show bbs-one-line">
                        {{stripHtmlTag(content.content)}}
                    </p>
                </div>
            </a>
            <div class="q-footer">
                <div class="q-tag">
                    {{ reStoreKeyValue('answer_type', content.type_id) }}
                </div>
                <div>
                    <i class="el-icon-view"></i>
                    <span>{{ content.sum && content.sum.view || 0 }}次浏览</span>
                </div>
                <div class="ml-3">
                    <i class="el-icon-time"></i>
                    <format-time :time="content.create_time" format="Y-m-d"></format-time>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormatTime from "@/components/common/format-time";
import {reStoreKeyValue,stripHtmlTag} from "@/utils/tools";

export default {
    name: "question",
    props: {
        content: {
            type: Object,
        },
        needUsername:{
            type:Boolean,
            default:true,
        }
    },
    components: {
        FormatTime
    },
    computed: {},
    data() {
        return {}
    },
    methods: {
        reStoreKeyValue,
        stripHtmlTag(str) {
            return str.replace(/<[^>]+>/g, "");
        }
    }
}
</script>

<style lang="less" scoped>
.q-detail {
    padding: 15px 0;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 5px;
    display: flex;

    &:hover {
        .answered {
            .a-title {
                color: #eee;
                background-color: #0082fc;
            }
        }
        .q-part {
            .q-title {
                .a-title {
                    color: #0082fc;
                    position: relative;
                    &::after {
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }
    }

    .answered {
        width: 50px;

        span {
            display: inline-block;
            width: 40px;
            height: 30px;
            color: #0082fc;
            line-height: 30px;
            text-align: center;
            position: absolute;
            background-color: #e4ecfd;
            border-radius: 5px;
        }
    }

    .q-part {
        width: 100%;
        flex: 1;
        padding: 0 10px;
        color: #617288;

        .q-user {
            font-size: 14px;
            padding-bottom: 10px;
            display: flex;
            height: 50px;
            align-items: center;

            .face {
                img {
                    width: 40px;
                    display: inline-block;
                    height: 40px;
                    border-radius: 100%;
                    padding: 2px;
                    border: 1px solid #ccc;
                }
            }

            .user-info {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding-left: 10px;
                justify-content: space-evenly;
                .username{
                    position: relative;
                    .official{
                        position: absolute;
                        color: #fff;
                        font-size: 12px;
                        padding: 2px 2px;
                        right: 20px;
                        top:-8px;
                        border-radius: 10px;
                        background-color: #007EE2;
                    }
                }
                .time {
                    display: inline-block;
                    //padding-left: 20px;
                    color: #B3C1D3;
                }
            }

        }


        .q-title {
            display: block;
            font-size: 16px;
            text-decoration: none;
            padding: 10px 0;
            cursor: pointer;
            position: relative;
            color: #333;

            .q-icon {
                //margin-right: 10px;
                top:12px;
                color: #0082fc;
                position: absolute;
            }

            .a-title {
                position: relative;
                display: inline-block;
                font-size: 18px;
                padding-bottom: 20px;
                padding-left: 30px;
                max-width: 92%;

                &::after {
                    position: absolute;
                    left: 30px;
                    bottom:10px;
                    content: ' ';
                    width: 0;
                    background-color: #0082fc;
                    transition: all ease-in-out .25s;
                    height: 2px;
                }
            }
            .q-info{
                display: flex;
                .thumb{
                    width: 120px;
                    height: 80px;
                    display: flex;
                    flex-shrink: 0;
                    align-items: flex-start;
                    justify-content: left;
                    img{
                        display: inline-block;
                        max-width: 95%;
                        max-height: 100%;
                    }
                }
            }
            .content-show{
                //margin-top: 20px;
                font-size: 14px;
            }
        }

        .q-footer {
            font-size: 12px;
            display: flex;
            padding-top: 10px;
            align-items: center;

            i {
                margin-right: 5px;
            }

            .q-tag {
                background-color: #E0EFFB;
                padding: 2px 15px;
                color: #1C8CE5;
                border: 1px solid #1C8CE5;
                border-radius: 10px;
                display: inline-block;
                margin-right: 20px;
                //padding-right: 20px;
            }
        }
    }
}
</style>
