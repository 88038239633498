<template>
    <div :class="{active:display}" v-if="list.length>0" class="search-result-container">
        <div>
            <div :class="{active:index===keyControl.index}" class="result-item" v-for="(item,index) in list"
                 :key="item.answer_id">
                <p @click="jumpUrl(item)" class="link">{{ item.title }}</p>
            </div>
        </div>
        <div style="display: none">
            <p class="history">
                历史
                <span class="remove">
                    <i class="el-icon-truck"></i>
                    清空
                </span>
            </p>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            baseUrl: '/bbs/search/index',
            display: false,
            timer: '',
            keyControl: {
                index: -1,
                sum: 0,
                value: '',
            },
            list: [],
        }
    },
    props: {
        show: {
            default: false,
        },
        value: {
            default: '',
        }
    },
    mounted() {
        this.bindEvent();
        this.search();
    },
    watch: {
        value: function (v) {
            if (v==this.keyControl.value){
                return;
            }
            if (!v) {
                this.display = false;
                return;
            }
            this.display = true;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.search();
            }, 100);
        }
    },
    methods: {
        bindEvent() {
            document.body.addEventListener('keyup', this.keyup)
            document.body.addEventListener('click', this.click);
        },
        click(){
            if (!this.display) return;
            this.display=false;
        },
        keyup: function (e) {
            if (!this.display) return;
            // if (!this.keyControl.sum) return;
            switch (e.which) {
                case 13:
                    //按下了回车键，那么进行事件发触发
                    this.enterEvent(this.value);
                    break;
                case 40:
                    //向下
                    this.keyControl.index = (this.keyControl.index + 1) % this.keyControl.sum;
                    this.select();
                    break;
                case 38:
                    //向上
                    if (this.keyControl.index - 1 < 0) {
                        this.keyControl.index = this.list.length - 1;
                    } else {
                        this.keyControl.index--;
                    }
                    this.select();
                    break;
            }
            // console.log(this.keyControl.index);
        },
        select(){
            let current=this.list[this.keyControl.index];
            if (!current||!current.title) return;
            this.keyControl.value=current.title;
            this.$emit('change',{value:current.title});
        },
        //按下回车事件的触发
        enterEvent(title){
            this.keyControl.value=title;
            this.display=false;
            this.$emit('change',{value:title,search:true});
        },
        search() {
            if (!this.value) return;
            this.keyControl.sum = 0;
            this.keyControl.index = -1;
            this.$bbs.get(this.baseUrl, {
                params: {
                    search: {
                        text: this.value,
                    }
                },
                need:{
                    progress:false,
                    loading:false,
                }
            }).then(data => {
                this.keyControl.sum = data.data.length;
                this.list = data.data;
            });
        },
        jumpUrl(item) {
            // this.display = false;
            // this.enterEvent(item.title);
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = `/wenda/detail/${item.answer_id}.html`;
            a.click();
        }
    },
}
</script>

<style scoped lang="less">
.search-result-container {
    position: absolute;
    width: 100%;
    z-index: 9;
    top: 50px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #aaa;
    transition: all ease-in-out 0.3s;
    opacity: 0;

    &.active {

        opacity: 1;
    }

    .result-item {
        padding: 8px 15px;
        cursor: pointer;
        color: #333;

        &.active, &:hover {
            background-color: #ddd;
        }
        p{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 24px;
        }
    }

    .history {
        user-select: none;
        color: #8590a6;

    }
}
</style>
