<template>
    <div class="question-list">
        <div class="question-type">
            <!--            <el-select @change="typeChange" size="small" class="my-select" v-model="type.active" placeholder="全部文章">-->
            <!--                <el-option-->
            <!--                    v-for="item in type.list"-->
            <!--                    :key="item.name"-->
            <!--                    :label="item.title"-->
            <!--                    :value="item.name">-->
            <!--                </el-option>-->
            <!--            </el-select>-->
            <ul>
                <li @click="seeHot(item)" v-for="item in second.list" :key="item.name">
                    <a :class="{active:type.active===item.name}" href="javascript:void(0);">{{ item.title }}</a>
                </li>
            </ul>
            <!--                        <div class="add-question">-->
            <!--                            <el-button type="primary" @click="addAnswer" icon="el-icon-plus">提问</el-button>-->
            <!--                        </div>-->
        </div>
        <div>
            <question v-for="q in answer" :key="q.answer_id" :content="q"></question>
        </div>
        <div v-if="mode!=='index'" class="q-footer-more">
            <div class="click-more">
                <span v-if="second.has_more" @click="getMore">
                    单击加载更多 <i class="el-icon-d-arrow-right"></i>
                </span>
                <span v-else>没有更多数据了</span>
            </div>
        </div>
        <!--        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"-->
        <!--                     :rules="form.rules" :data="form.data"></form-dialog>-->
    </div>
</template>

<script>
import Question from "@/components/wenda/left/question";
import F from "@/fields/fields";

export default {
    name: "question-list",
    props: {
        mode: {
            default: '',
        }
    },
    components: {
        Question
    },
    mounted() {
        let active = this.$route.params && this.$route.params.type || 'recommend';
        let all = this.second.list.map(v => v.name);
        this.type.active = all.indexOf(active) > -1 ? active : 'recommend';
    },
    watch: {
        'type.active': function () {
            this.answer.splice(0, 100000);
            this.search.answer_id = [];
            this.search.pageNumber=1;
            this.search.type_id = this.type.active;
            this.getAnswer();
        }
    },
    data() {
        return {
            baseRecommendUrl: '/bbs/hot/recommend',
            baseUrl: '/bbs/answer/index',
            baseHotUrl: '/bbs/hot/index',
            baseNewsUrl: '/bbs/news/index',
            answer: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            },
            search: {
                pageNumber:1,
                answer_id: [],
            },
            second: {
                active: '',
                has_more: 0,//这个是用来计算本次获取了多少条数据，方便展示更多
                list: [
                    {title: '推荐', name: 'recommend'},
                    {title: '资讯', name: 'news'},
                    {title: '热榜', name: 'hot'},
                    // {title: '开源引力', name: 'open'},
                    {title: 'BIGEMAP PRO', name: 'pro'},
                    {title: 'BIGEMAP SERVER', name: 'server'},
                    {title: 'BIGEMAP APP', name: 'app'},
                ]
            },
            type: {
                active: 'index',
                list: [
                    {
                        name: 'index',
                        title: '全部文章'
                    },
                    {
                        name: 'unanswered',
                        title: '等待回答'
                    },
                    {
                        name: 'done',
                        title: '已完成'
                    },
                ],
            }
        }
    },

    methods: {
        // typeChange() {
        //     location.href = '/wenda/' + this.type.active;
        // },
        // addAnswer() {
        //     if (!this.$tools.checkLogin()) {
        //         return;
        //     }
        //     let form = F.get('answer', 'add');
        //     form.action_name = 'addAnswer';
        //     this.form = form;
        // },
        // submit: function (data) {
        //     // this.con(data);
        //     if (data.type === 'cancel') {
        //         this.form.show = false;
        //         return;
        //     }
        //     switch (this.form.action_name) {
        //         case 'addAnswer':
        //             this.$bbs.post(this.baseUrl, data.data).then(bbsData => {
        //                 this.$message.success('保存成功!');
        //                 this.form.show = false;
        //                 if (this.query.status !== 100) {
        //                     //只有当前不是查询的已经完成的问题，才会添加
        //                     this.answer.unshift(bbsData.data);
        //                 }
        //             });
        //             break;
        //     }
        // },
        seeHot(item) {
            this.type.active = item.name;
            this.$router.push({
                path: '/wenda/' + item.name,
            });
        },
        getAnswer() {
            // let mapping = {done: 100, unanswered: 1};
            // let status = mapping[this.type.active] || '';
            let url = this.baseUrl;
            switch (this.type.active) {
                case 'recommend':
                    url = this.baseRecommendUrl;
                    break;
                case 'hot':
                    url = this.baseHotUrl;
                    break;
                case 'news':
                    url = this.baseNewsUrl;
                    break;
            }
            this.$bbs.get(url, {
                params: {
                    pageSize: this.mode === 'index' ? 5 : 10,
                    search: this.search,
                    pageNumber:this.search.pageNumber,
                    type:'hot',
                }
            }).then(data => {
                let need=data.data.hot||data.data.data;
                this.answer.push(...need);
                this.second.has_more = need.length === 10;
                // need.map(v => {
                //     this.search.answer_id = ['answer_id', 'lt', v.answer_id];
                // });
            });
        },
        getMore() {
            this.search.pageNumber++;
            this.getAnswer();
        }
    }
}
</script>

<style scoped lang="less">
.question-list {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;

    .question-type {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        border-bottom: 1px solid #ccc;

        .my-select {
            border-radius: 50px;
            width: 112px;

            ::v-deep {
                .el-input__inner {
                    border-color: #007EE2;
                    border-radius: 20px;
                }
            }
        }

        ul {
            //flex: 1;
            //padding-left: 100px;
            display: flex;
            margin-bottom: 10px;

            li {
                border-radius: 50px;

                &:hover {
                    background-color: #eef3fa;
                }

                &.active {
                    a {
                        color: #0082fc;
                        border-bottom: 2px solid #0082fc;
                    }
                }

                a {
                    text-decoration: none;
                    display: inline-block;
                    border: 1px solid transparent;
                    padding: 6px 20px;
                    font-size: 14px;
                    color: #617288;
                    letter-spacing: 1px;

                    &.active {
                        color: #007EE2;
                        border-radius: 50px;
                        border: 1px solid #007EE2;
                    }
                }
            }
        }

        .add-question {
            width: 90px;
        }
    }
}

.click-more {
    text-align: center;
    font-size: 14px;
    background: #e4ecfd;
    border-radius: 2px;
    padding: 15px 0;
    color: #8098B5;
    position: relative;
    cursor: pointer;
}
</style>
